import { _get, _patch, _post, _put } from "@/services/utils";
import { endpoint } from "@/services/endpoint";

export const requestApi = {
  async getLatestRequests(params: any) {
    return _get(endpoint.request + "/latest", params);
  },

  async getLatestComments(params: any) {
    return _get(endpoint.request + "/latest-comment", params);
  },

  async getRequest(params: any) {
    return _get(endpoint.request, params);
  },

  async createRequest(payload: CreateRescueRequest) {
    return _post(endpoint.request, payload);
  },

  async updateRequest(payload: any) {
    return _put(endpoint.request, payload);
  },

  async uploadImage(payload: FormData) {
    return _post(`/uploads`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  async getRequestDetail(id: string) {
    return _get(`${endpoint.request}/${id}`);
  },

  async createRequestComment(payload: CreateRescueRequestComment) {
    return _post(endpoint.request + "/comment", payload);
  },

  async createRequestAccept(payload: CreateRescueRequestAccept) {
    return _patch(endpoint.request + "/accept", payload);
  },

  async createRequestComplete(payload: CreateRescueRequestComplete) {
    return _patch(endpoint.request + "/complete", payload);
  },

  async getCounts() {
    return _get(endpoint.request + "/count-by-status");
  }
};
