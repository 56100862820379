"use client";

import { SendSupportRequestButton } from "@/components/Button";
import { SupportMessage } from "@/components/SupportMessage";
import React from "react";

export default function RequestSupport() {
  return (
    <div className="order-1 md:order-2 flex flex-col items-center gap-10 max-md:gap-5">
      <SupportMessage />
      <SendSupportRequestButton />
    </div>
  );
}
