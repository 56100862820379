import { PROVINCE_DEFAULT } from "@/configs";

export const PROVINCES_LOCAL_STORE = 'provinces';
export const REQUEST_TYPE_LOCAL_STORE = 'requestType';


export const setProvincesLocal = (value: any) => {
  localStorage.setItem(PROVINCES_LOCAL_STORE, JSON.stringify(value));
}

export const getProvincesLocal = () => {
  if (typeof window == "undefined") return [];
  const provinces = localStorage.getItem(PROVINCES_LOCAL_STORE);
  return provinces ? JSON.parse(provinces) : [];
}

export const setRequestTypeLocal = (value: any) => {
  localStorage.setItem(REQUEST_TYPE_LOCAL_STORE, JSON.stringify(value));
}

export const getRequestTypeLocal = () => {
  if (typeof window == "undefined") return [];
  const requestType = localStorage.getItem(REQUEST_TYPE_LOCAL_STORE);
  return requestType ? JSON.parse(requestType) : [];
}

export const getIdProvinceDefault = () => {
  if (typeof window == "undefined") return null;
  const provinces = getProvincesLocal();
  if(provinces.length === 0 ) return null;
  const find = provinces.find((item: any) => item.code === PROVINCE_DEFAULT);

  return find ? find.id : null;
}
