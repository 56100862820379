import {
  PhoneIcon,
  UserIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import { MapPinIcon } from "@heroicons/react/24/outline";
import Paragraph from "antd/es/typography/Paragraph";
import React from "react";

interface IInfoCard {
  data: IDataCard;
  isSelected?: boolean;
}

interface IDataCard {
  fullName?: string;
  phoneNumber?: string;
  fullAddress?: string;
  content?: string;
}
export default function InfoCard({ data, isSelected = false }: IInfoCard) {
  return (
    <div className="break-all w-full">
      {data?.fullName && (
        <div className="flex items-center gap-2 mb-2 w-[70%]">
          <div className={"w-[18px] h-[18px]"}>
            <UserIcon width={18} />
          </div>
          <span
            className={`font-bold break-normal ${
              !isSelected ? "text-primary" : "text-white"
            }`}
          >
            {data?.fullName}
          </span>
        </div>
      )}
      {data?.phoneNumber && (
        <div className="flex items-center gap-2 mb-2">
          <div className={"w-[18px] h-[18px]"}>
            <PhoneIcon width={18} />
          </div>
          <span className={"text-sm"}>{data?.phoneNumber}</span>
        </div>
      )}
      {data?.fullAddress && (
        <div className="flex items-center gap-2 mb-2">
          <div className={"w-[18px] h-[18px]"}>
            <MapPinIcon width={18} />
          </div>
          <span className="italic text-sm break-normal">
            {data?.fullAddress}
          </span>
        </div>
      )}
      {data?.content && (
        <div className="flex items-start gap-2 mb-2">
          <div className={"w-[18px] h-[18px] mt-[1px]"}>
            <ChatBubbleBottomCenterTextIcon width={18} />
          </div>
          <Paragraph
            className={`text-sm text-start whitespace-break-spaces ${
              isSelected && "text-white"
            }`}
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: "Xem thêm",
            }}
          >
            {data?.content}
          </Paragraph>
        </div>
      )}
    </div>
  );
}
