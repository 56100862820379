"use client";

import dynamic from "next/dynamic";

const GGmap = dynamic(() => import("@/components/GGmap"), {
  ssr: false,
});

interface MarkerProps {
  lat: number;
  lng: number;
  text: string;
  phone: string;
  desc: string;
}

export default function MapArea({
  markers = [],
  center,
  searchEnable = true,
}: {
  markers?: MarkerProps[];
  center?: {
    lat: number;
    lng: number;
  };
  searchEnable?: boolean;
}) {
  return (
    <div className="w-full h-[650px] mt-0">
      <GGmap markers={markers} center={center} />
    </div>
  );
}
