"use client";

import { QUERY_KEY } from "@/enum/queryKeys";
import { ERROR_MESSAGE } from "@/blocks/RequestSupportBlock/constants/messages";
import { services } from "@/services";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Col, Form, Input, Modal, notification, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";

interface IConfirmModal {
  id: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  type: "receive" | "finish";
  onRefetch: () => void;
}

export default function ConfirmModal({
  id,
  open,
  setOpen,
  type,
  onRefetch,
}: IConfirmModal) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    const payload = {
      request_id: id,
      phone_number: values.phone,
      full_name: values.name,
      note: values.note,
    };

    if (type === "receive") {
      handleCreateRequestAccept(payload);
    } else {
      handleCreateRequestComplete(payload);
    }

    await queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.GET_LIST_REQUEST],
      exact: true,
      refetchType: "active",
    });
  };

  const handleCreateRequestAccept = async (
    payload: CreateRescueRequestAccept
  ) => {
    setIsLoading(true);
    try {
      const res = await services.requestApi.createRequestAccept(payload);

      if (res.data) {
        notification.success({
          message: "Thành công",
          description: "Nhận yêu cầu hỗ trợ thành công",
        });
        onRefetch();
        setOpen(false);
        form.resetFields();
      } else {
        notification.error({
          message: "Lỗi",
          description: "Có lỗi xảy ra, vui lòng thử lại",
        });
      }
    } catch (error) {
      console.log("error", error);
      notification.error({
        message: "Lỗi",
        description: "Có lỗi xảy ra, vui lòng thử lại",
      });
    }
    setIsLoading(false);
  };

  const handleCreateRequestComplete = async (
    payload: CreateRescueRequestComplete
  ) => {
    setIsLoading(true);
    try {
      const res = await services.requestApi.createRequestComplete(payload);

      if (res.data) {
        notification.success({
          message: "Thành công",
          description: "Xác nhận hoàn thành cứu trợ thành công",
        });
        onRefetch();
        setOpen(false);
        form.resetFields();
      } else {
        notification.error({
          message: "Lỗi",
          description: "Có lỗi xảy ra, vui lòng thử lại",
        });
      }
    } catch (error) {
      console.log("error", error);
      notification.error({
        message: "Lỗi",
        description: "Có lỗi xảy ra, vui lòng thử lại",
      });
    }
    setIsLoading(false);
  };

  return (
    <Modal
      destroyOnClose
      open={open}
      onCancel={() => {
        setOpen(false);
        form.resetFields();
      }}
      footer={<></>}
    >
      <div className="flex flex-col gap-2">
        <span className="text-2xl font-bold text-center">
          {type === "receive"
            ? "Nhận yêu cầu hỗ trợ"
            : "Xác nhận hoàn thành cứu trợ"}
        </span>
        <span className="text-center">
          Vui lòng chỉ xác nhận nếu thực sự có khả năng giúp! Rất nhiều người
          dân đang cần sự trợ giúp thật sự. Cảm ơn bạn đã chung tay!
        </span>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="mt-4"
        >
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                className="font-semibold"
                name="name"
                label="Họ và tên"
                rules={[
                  { required: true, message: ERROR_MESSAGE.VALIDATE_NAME },
                ]}
              >
                <Input placeholder="Nhập họ và tên" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                className="font-semibold"
                name="phone"
                label="Số điện thoại"
                rules={[
                  { required: true, message: ERROR_MESSAGE.VALIDATE_PHONE },
                  {
                    pattern: new RegExp(/^(03|05|07|08|09)\d{8}$/),
                    message: ERROR_MESSAGE.VALIDATE_PHONE_NUMBER,
                  },
                ]}
              >
                <Input placeholder="Nhập số điện thoại" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            className="font-semibold"
            name="note"
            label="Ghi chú"
            rules={[
              {
                required: type === "receive" ? false : true,
                message: ERROR_MESSAGE.VALIDATE_NOTE2,
              },
            ]}
          >
            <TextArea rows={3} placeholder="Nhập ghi chú" />
          </Form.Item>
          <Button
            loading={isLoading}
            onClick={() => form.submit()}
            size="large"
            className="w-full py-5 bg-primary text-white mt-5"
          >
            {type === "receive" ? "Hỗ trợ người này" : "Xác nhận"}
          </Button>
        </Form>
      </div>
    </Modal>
  );
}
