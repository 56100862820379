"use client";

import { Button, Col, Empty, Input, Row, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import useResponsive from "@/hooks/useResponsive";
import {
  ListBulletIcon,
  MagnifyingGlassIcon,
  MapIcon,
} from "@heroicons/react/24/outline";
import RequestCard from "@/components/RequestCard";
import CommentCard from "@/components/CommentCard";
import ConfirmModal from "@/components/ConfirmModal";
import { MapArea } from "../components";
import {
  getIdProvinceDefault,
  getRequestTypeLocal,
} from "@/utilities/configs.util";
import ScrollLoadMore from "@/components/ScrollLoadMore/ScrollLoadMore";
import { services } from "@/services";
import { requestStatus } from "@/enum";
import { useDebounceValue } from "usehooks-ts";
import { composeClassName } from "@/utilities/general.util";

type TabEnum = "list" | "map";

const statisticDefault: RescueStatistic[] = [
  {
    label: "Quá thời gian",
    quantity: 0,
    status: "overdue",
  },
  {
    label: "Chưa hoàn thành",
    quantity: 0,
    status: "in-progress",
  },
  { label: "Đã hoàn thành", quantity: 0, status: "supported" },
];

export default function ListRequest() {
  const { isSm } = useResponsive();
  const [selectedRequest, setSelectedRequest] = useState<RescueRequest>();
  const [selectedRequestType, setSelectedRequestType] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<TabEnum | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const requestTypeList = [
    { value: "", label: "Tất cả" },
    ...getRequestTypeLocal().map((item: any) => {
      return { value: item.id, label: item.name };
    }),
  ];
  const scrollRef = useRef<HTMLInputElement>(null);
  const onScrollTop = () => {
    scrollRef.current?.scrollTo({
      top: 0,
    });
  };
  const [requestCurrentPage, setRequestCurrentPage] = useState<number>(1);
  const [requestTotalPage, setRequestTotalPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [debounceValue, setValue] = useDebounceValue(searchValue, 500);
  const [requestList, setRequestList] = useState<RescueRequest[]>([]);
  const [commentList, setCommentList] = useState([]);
  const [statistic, setStatistic] =
    useState<RescueStatistic[]>(statisticDefault);
  useEffect(() => {
    if (searchValue) setValue(searchValue);
  }, [searchValue, setValue]);

  useEffect(() => {
    if (!isSm) {
      setSelectedTab("list");
    } else {
      setSelectedTab(null);
    }
  }, [isSm]);

  useEffect(() => {
    getListRequests(1);
  }, [selectedRequestType, debounceValue]);

  useEffect(() => {
    getListComments();
  }, []);

  const getListRequests = async (page: number) => {
    setLoading(true);
    try {
      const params = {
        province_id: getIdProvinceDefault(),
        ...(selectedRequestType && { request_type_id: selectedRequestType }),
        status: requestStatus.PENDING,
        limit: 20,
        search: debounceValue,
        page: page || requestCurrentPage,
      };
      const res: any = await services.requestApi.getLatestRequests(params);

      if (res.data) {
        if (page === 1) {
          onScrollTop();
          setRequestList(res.data.data.items);
        } else {
          setRequestList((prev) => [...prev, ...res.data.data.items]);
        }
        setRequestTotalPage(res.data.meta.totalPages);
        setRequestCurrentPage(res.data.meta.currentPage);
      }
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const getListComments = async () => {
    setLoading(true);
    try {
      const params = {
        limit: 20,
        page: 1,
      };
      const res: any = await services.requestApi.getLatestComments(params);
      if (res.data) {
        setCommentList(res.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const onChangeSearch = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const onRefetch = () => {
    getListRequests(1);
    getListComments();
  };

  // useEffect(() => {
  //   fetchCountStatus();
  // }, []);

  // const fetchCountStatus = async () => {
  //   try {
  //     const res: any = await services.requestApi.getCounts();

  //     if (res.data) {
  //       const list: any[] = JSON.parse(JSON.stringify(statisticDefault));

  //       if (list.length > 0) {
  //         list[0].quantity = res.data.totalOverTime;
  //         list[1].quantity = res.data.totalPending;
  //         list[2].quantity = res.data.totalCompleted;

  //         setStatistic(list);
  //       }

  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };

  return (
    <div className="order-3 flex flex-col w-full h-full py-5 bg-white rounded-xl max-md:pt-2">
      <div className="flex flex-col gap-6 w-full text-start">
        <span className="text-2xl font-bold text-primary">
          Danh sách yêu cầu mới nhất
        </span>
        <span className="-mt-5 text-sm">{`Hãy "Nhận yêu cầu" nếu bạn có khả năng giúp đỡ`}</span>
        {/* <Row className="flex items-center gap-3 md:hidden">
          {statistic.map((item) => (
            <Col
              md={4}
              key={item.status}
              className={composeClassName(
                "text-white py-1 px-2 rounded-md flex gap-1 items-center",
                item.status === "overdue" && "bg-red-600",
                item.status === "in-progress" && "bg-[#ff6600ff]",
                item.status === "supported" && "bg-success"
              )}
            >
              <span className="text-sm font-bold">{item.quantity}</span>
              <span className="text-xs">{item.label}</span>
            </Col>
          ))}
        </Row> */}
        <div className="flex flex-col md:flex-row gap-5">
          <div className="flex flex-col gap-4 w-full md:w-[50%]">
            <div className="flex gap-7">
              {/* <div className="flex flex-col gap-1 w-full text-start">
                <span className="text-sm font-bold">Thành phố/Tỉnh</span>
                <Select options={provinceList} />
              </div> */}
              <div className="flex flex-col gap-1 w-full text-start">
                <span className="text-base font-bold">Loại cứu trợ</span>
                <Select
                  defaultValue={""}
                  onChange={(value) => {
                    setSelectedRequestType(value);
                  }}
                  size="large"
                  options={requestTypeList}
                />
              </div>
            </div>
            <Input
              size="large"
              onChange={onChangeSearch}
              placeholder="Tìm kiếm theo tên, địa bàn khu vực và số điện thoại."
              prefix={<MagnifyingGlassIcon width={18} />}
            />
            <div className="flex gap-3 md:hidden justify-end mb-1">
              <Button
                size="small"
                className={`w-[120px] py-[14px] rounded-md border-primary ${
                  selectedTab === "list"
                    ? "bg-primary text-white hover:!bg-primary hover:!text-white"
                    : "text-primary"
                }`}
                icon={<ListBulletIcon width={18} />}
                onClick={() => setSelectedTab("list")}
              >
                Danh sách
              </Button>
              <Button
                size="small"
                className={`w-[120px] py-[14px] rounded-md border-primary ${
                  selectedTab === "map"
                    ? "bg-primary text-white hover:!bg-primary hover:!text-white"
                    : "text-primary"
                }`}
                icon={<MapIcon width={18} />}
                onClick={() => setSelectedTab("map")}
              >
                Bản đồ
              </Button>
            </div>
            {(selectedTab === "list" || !selectedTab) && (
              <div
                ref={scrollRef}
                className="max-md:max-h-[505px] md:h-[515px] overflow-auto"
              >
                <ScrollLoadMore
                  pageIndex={requestCurrentPage}
                  totalPage={requestTotalPage}
                  loading={loading}
                  changePage={getListRequests}
                >
                  <div className="flex flex-col gap-2 p-3 bg-gray-50 h-fit rounded-xl shadow">
                    {requestList.map((item) => (
                      <RequestCard
                        key={item.id}
                        data={item}
                        isSelected={item === selectedRequest}
                        setSelectedRequest={setSelectedRequest}
                        setOpenConfirmModal={setOpenModal}
                      />
                    ))}
                    {!requestList.length && (
                      <Empty description="Không có yêu cầu nào" />
                    )}
                  </div>
                </ScrollLoadMore>
              </div>
            )}
          </div>
          <div className="w-full md:w-[50%]">
            {(selectedTab === "map" || !selectedTab) && (
              <MapArea
                markers={requestList.map((item) => {
                  return {
                    lat: Number(item.latitude),
                    lng: Number(item.longitude),
                    text: item.fullName,
                    phone: item.phoneNumber,
                    desc: item.content,
                  };
                })}
                {...(selectedRequest && {
                  center: {
                    lat: Number(selectedRequest?.latitude),
                    lng: Number(selectedRequest?.longitude),
                  },
                })}
              />
            )}
          </div>
        </div>
      </div>
      {(selectedTab === "list" || !selectedTab) && (
        <div className="flex flex-col gap-4 mt-14 max-md:mt-5">
          <span className="text-2xl font-bold text-primary">
            Danh sách bình luận mới nhất
          </span>
          <div className="flex flex-col grid-cols-2 gap-2 p-3 bg-gray-50 max-md:h-[350px] overflow-auto rounded-xl shadow md:grid">
            {commentList.map((item, index) => (
              <CommentCard key={index} data={item} />
            ))}
            {!commentList ||
              (commentList.length <= 0 && (
                <Empty description="Không có bình luận nào" />
              ))}
          </div>
        </div>
      )}
      {openModal && selectedRequest && (
        <ConfirmModal
          open={openModal}
          setOpen={setOpenModal}
          type="receive"
          onRefetch={onRefetch}
          id={selectedRequest?.id}
        />
      )}
    </div>
  );
}
