import React, { useEffect, useRef } from "react";
interface ScrollLoadMoreProps {
  pageIndex: number;
  changePage: (page: number) => void;
  totalPage: number;
  loading?: boolean;
  children: React.ReactNode;
}

const ScrollLoadMore: React.FC<ScrollLoadMoreProps> = ({
  pageIndex,
  totalPage,
  loading,
  changePage,
  children,
}) => {
  const elmEndItem = useRef(null);
  const options = {
    root: null,
    rootMargin: "5px",
    threshold: 0.5,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      // if (entry.intersectionRatio > 0) {
      if (
        entry.isIntersecting &&
        totalPage &&
        pageIndex < totalPage &&
        !loading
      ) {
        changePage(pageIndex + 1);
      }
      // observer.unobserve(entry.target);
    }, options);
    if (elmEndItem.current) {
      observer.observe(elmEndItem.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [elmEndItem, totalPage, pageIndex, loading]);
  return (
    <div className="scroll-loadmore position-rel">
      {children}
      {loading && ""}
      <div id="end_data" ref={elmEndItem} />
    </div>
  );
};

export default ScrollLoadMore;
