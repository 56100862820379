import { MapPinIcon } from "@heroicons/react/24/outline";
import { Button } from "antd";
import { useRouter } from "next/navigation";
import React from "react";
import InfoCard from "../InfoCard";

interface IRequestCard {
  data: RescueRequest;
  isSelected: boolean;
  setSelectedRequest: (value: any) => void;
  setOpenConfirmModal: (value: boolean) => void;
}
export default function RequestCard({
  data,
  isSelected,
  setSelectedRequest,
  setOpenConfirmModal,
}: IRequestCard) {
  const router = useRouter();
  return (
    <div
      className={`p-4 flex flex-col relative border border-solid rounded-lg border-gray-200 ${
        isSelected ? "bg-primary text-white" : "bg-white"
      }`}
      onClick={() => setSelectedRequest(data)}
    >
      <div className="flex w-full justify-between relative">
        <div className="w-full">
          <InfoCard
            data={{
              fullName: data?.fullName,
              phoneNumber: data?.phoneNumber,
              fullAddress: data?.fullAddress,
            }}
            isSelected={isSelected}
          />
        </div>
        <div
          className={`absolute top-0 right-0 cursor-pointer text-xs text-right underline ${
            !isSelected && "text-primary"
          }`}
          onClick={() => router.push("/request-detail/" + data.id)}
        >
          Xem chi tiết
        </div>
      </div>
      <div className="ml-7 mt-4 flex justify-between items-center">
        {" "}
        <Button
          className={`w-40 p-4 rounded-2xl border-primary ${
            !isSelected && "text-primary"
          }`}
          onClick={() => {
            setSelectedRequest(data);
            setOpenConfirmModal(true);
          }}
        >
          Nhận yêu cầu
        </Button>
      </div>
    </div>
  );
}
