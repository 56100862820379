"use client";

import React, { useEffect } from "react";
import { StatisticCard } from "../components";
import { services } from "@/services";

const statisticDefault: RescueStatistic[] = [
  {
    label: "Quá thời gian",
    quantity: 0,
    status: "overtime",
  },
  {
    label: "Chưa hoàn thành",
    quantity: 0,
    status: "in_progress",
  },
  { label: "Đã hoàn thành", quantity: 0, status: "completed" },
];

export default function Statistics() {
  const [statistic, setStatistic] =
    React.useState<RescueStatistic[]>(statisticDefault);
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    fetchCountStatus();
  }, []);

  const fetchCountStatus = async () => {
    try {
      const res: any = await services.requestApi.getCounts();

      if (res.data) {
        const list: any[] = JSON.parse(JSON.stringify(statisticDefault));

        if (list.length > 0) {
          list[0].quantity = res.data.totalOverTime;
          list[1].quantity = res.data.totalInProgress;
          list[2].quantity = res.data.totalCompleted;

          setStatistic(list);
        }

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="order-1 flex flex-row flex-wrap w-full justify-center md:mt-3 gap-5 max-md:gap-3">
      {statistic.map(({ label, quantity, status }) => (
        <StatisticCard
          label={label}
          quantity={quantity}
          status={status}
          key={status}
          loading={loading}
        />
      ))}
    </div>
  );
}
