export enum errorCode {
  INVALID_REQUEST = 400,
  INVALID_API_KEY = 401,
}

export enum requestStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}
