import { composeClassName } from "@/utilities/general.util";
import React from "react";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function PrimaryButton(props: Props) {
  return (
    <button
      {...props}
      className={composeClassName(
        props.className,
        "w-[300px] h-[65px] bg-primary hover:bg-hover text-white rounded-lg max-md:h-[50px] max-md:w-[250px]"
      )}
    />
  );
}
