import { composeClassName } from "@/utilities/general.util";
import { Spin } from "antd";
import { useRouter } from "next/navigation";

type Props = Pick<RescueStatistic, "label" | "quantity" | "status" | "loading">;

export default function StatisticCard({
  label,
  quantity,
  status,
  loading,
}: Props) {
  const router = useRouter();

  return (
    <Spin spinning={loading}>
      <button
        className="flex justify-center text-center items-center content-center"
        onClick={() => router.push(`/requests?status=${status}`)}
      >
        <div
          className={composeClassName(
            "flex flex-row md:flex-col md:w-[300px] md:h-[90px] gap-2 rounded-lg md:rounded-md justify-center align-middle items-center py-[2px] px-2 text-white text-center content-center",
            status === "overtime" && "bg-red-600",
            status === "in_progress" && "bg-[#ff6600ff]",
            status === "completed" && "bg-success"
          )}
        >
          <h1 className="font-bold text-3xl text-white max-md:text-base">
            {quantity}
          </h1>
          <p className="text-sm max-md:text-xs">{label}</p>
        </div>
      </button>
    </Spin>
  );
}
