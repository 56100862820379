import React from "react";
import { getTimeDifference } from "@/utilities/date.util";
import Paragraph from "antd/es/typography/Paragraph";

interface ICommentCard {
  data: CommentItem;
}
export default function CommentCard({ data }: ICommentCard) {
  return (
    <div
      className={`p-4 flex flex-col relative border border-solid rounded-lg border-gray-200 bg-white`}
    >
      <div className="w-full flex justify-between">
        <p className={`text-base font-bold`}>
          <span className="block md:inline-block">{data?.fullName}</span> <span className="hidden md:inline-block">-</span> <span>{data?.phoneNumber}</span>
        </p>
        <p className="text-xs text-gray-500 italic">
          {getTimeDifference(data?.createdAt)}
        </p>
      </div>
      <Paragraph
        className="text-sm text-start whitespace-break-spaces"
        ellipsis={{
          rows: 3,
          expandable: true,
          symbol: "Xem thêm",
        }}
      >
        {data?.content}
      </Paragraph>
    </div>
  );
}
