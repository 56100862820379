import React from "react";

export default function SupportMessage() {
  return (
    <div className="text-center">
      <h1 className="text-3xl font-semibold text-center max-md:text-2xl">
        Nếu bạn cần giúp đỡ, hãy gửi yêu cầu cho chúng tôi tại đây
      </h1>
      <p className="text-sm text-red-600 italic text-center mt-2">
        Hãy chia sẻ cho mọi người cùng cập nhật thông tin cần hỗ trợ, các đội hỗ
        trợ sẽ đến sớm. <br /> Đừng lo lắng, mọi người bên bạn!
      </p>
    </div>
  );
}
